export default {
  link: '',
  previewSrc: 'futures.png',
  title: 'Журнал «Идеология будущего»',
  announce:
    'Общественно-политический, научно-теоретический, экспертно-аналитический журнал',
  model_type: 'specialprojectsection',
  type: 'third',
  gallery: [
    {
      static: 'other',
      previewSrc: 'ideology_of_future_part14.jpg',
      label: 'Выпуск 14',
      link: 'https://histrf.ru/files/ideology_of_future_14.pdf',
      title: '',
    },
    {
      static: 'other',
      previewSrc: 'ideology_of_future_part13.png',
      label: 'Выпуск 13',
      link: 'https://histrf.ru/files/ideology_of_future_13_v2.pdf',
      title: '',
    },
    {
      static: 'other',
      previewSrc: 'ideology_of_future_part12.png',
      label: 'Выпуск 12',
      link: 'https://histrf.ru/files/ideology_of_future_12_v2.pdf',
      title: '',
    },
    {
      static: 'other',
      previewSrc: 'ideology_of_future_part11.png',
      label: 'Выпуск 11',
      link: 'https://histrf.ru/files/ideology_of_future_11_v2.pdf',
      title: '',
    },
    {
      static: 'other',
      previewSrc: 'ideology_of_future_part10.jpg',
      label: 'Выпуск 10',
      link: 'https://histrf.ru/files/ideology_of_future_10.pdf',
      title: '',
    },
    {
      static: 'other',
      previewSrc: 'ideology_of_future_part9.jpg',
      label: 'Выпуск 9',
      link: 'https://histrf.ru/files/ideology_of_future_9_v2.pdf',
      title: '',
    },
    {
      static: 'other',
      previewSrc: 'ideology_of_future_part8.jpg',
      label: 'Выпуск 8',
      link: 'https://histrf.ru/files/ideology_of_future_8_v2.pdf',
      title: '',
    },
    {
      static: 'other',
      previewSrc: 'ideology_of_future_part7.jpg',
      label: 'Выпуск 7',
      link: 'https://histrf.ru/imgs/RVIO_7_2022.pdf',
      title: '',
    },
    {
      static: 'other',
      previewSrc: 'ideology_of_future_part6.jpg',
      label: 'Выпуск 6',
      link: 'https://histrf.ru/files/ideology_of_future_6.pdf',
      title: '',
    },
    {
      static: 'other',
      previewSrc: 'ideology_of_future_part5.jpeg',
      label: 'Выпуск 5',
      link: 'https://histrf.ru/files/ideology_of_future_part5.pdf',
      title: '',
    },
    {
      static: 'other',
      previewSrc: 'future-idiology.png',
      label: 'Выпуск 4',
      link: 'https://histrf.ru/magazine/release/ideologiya-budushchego-obshchestvenno-politicheskiy-nauchno-teoreticheskiy-ekspertno-analiticheskiy-zhurnal-2021-4',
      title: '',
    },
    {
      static: 'other',
      previewSrc: 'ideology_of_future_part3.png',
      label: 'Выпуск 3',
      link: 'https://histrf.ru/magazine/release/ideologiya-budushchego-obshchestvenno-politicheskiy-nauchno-teoreticheskiy-ekspertno-analiticheskiy-zhurnal-2021-3',
      title: '',
    },
    {
      static: 'other',
      previewSrc: 'ideology_of_future_part2.jpeg',
      label: 'Выпуск 2',
      link: 'https://histrf.ru/magazine/release/ideologiya-budushchego-obshchestvenno-politicheskiy-nauchno-teoreticheskiy-ekspertno-analiticheskiy-zhurnal-2021-2',
      title: '',
    },
    {
      static: 'other',
      previewSrc: 'future-idiology.png',
      label: 'Выпуск 1',
      link: 'https://histrf.ru/magazine/release/ideologiya-budushchego-obshchestvenno-politicheskiy-nauchno-teoreticheskiy-ekspertno-analiticheskiy-zhurnal-2021-1',
      title: '',
    },
  ],
};
